@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:wght@400;500&display=swap');
.App {
    text-align: left;
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    background-color: var(--backgroundColor);
    font-family: 'Libre Baskerville', serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.app-bar{
    position: fixed;
    right: 0;
    left: 0;
    max-width: min(650px,90vw);
    margin: 0 auto;
    padding: 10px 10px;
}
.with-right-margin{
    margin-right: 4px;
}
.icons{
    color: var(--primaryColor);
}
.app-bar-icons{
    color: var(--disabledColor);
    cursor: pointer;
}
.app-bar-icons:hover{
    color: var(--primaryColor);
}
:root {
    --primaryColor: #2660A4;
    --disabledColor: #658ebd;
    --accentColor: #F19953;
    --complementaryColor: #FFDE91;
    --backgroundColor: #EDF7F6;
    --backgroundColor1: #ffeda1;
    --textColor: #4b4b4b;
    --seperatorColor: #120D31;
    --secondaryTextColor: #9d9d9d;
    background: rgba(0, 0, 0, 0.0) !important;
}

* {
    -webkit-font-smoothing: antialiased;
}


.title {
    font-weight: 700;
    font-size: 4em !important;
    color: var(--primaryColor);
    margin-bottom: 32px;
}
.title-container{
    margin-bottom: 24px;
    padding-right: 24px;
    padding-left: 24px;
}
.title-row{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}
.react-typewriter-text-wrap {
    margin-bottom: 8px;

}

.react-typewriter-text {
    font-weight: 700;
    color: var(--primaryColor);
    margin-bottom: 32px;
    white-space: nowrap;
}

.definition-container {
    font-size: 18px;
    line-height: 1.2em;
    display: flex;
    margin-bottom: 16px;
    margin-right: 24px;
    margin-left: 24px;
}

.spelling-container{
    color:var(--primaryColor);
    opacity: 0.7;
    font-weight: bold;
    font-size: 18px;
    margin-left: 4px;
    display: flex;
}

.definition-bullet {
    margin-right: 6px;
    flex-flow: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.definition-number {
    margin-right: 4px;
    font-weight: 500;
    color: var(--textColor);

}

.definition-pos {
    color: var(--accentColor);
}

.sign-language {
    width: min(650px, 100%);
    overflow-x: scroll;
    align-content: center;
    text-align: center;
    text-align: -webkit-center;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.definition-text {
    flex: 6;
    color: var(--textColor);
    letter-spacing: 0.021em;
}

p {
    margin: 0 !important;
}

.example-text {
    color: var(--secondaryTextColor);
    line-height: 1;
    font-size: 16px;
}

a:hover {
    cursor: pointer;
}
@media only screen and (orientation: portrait) and (max-width: 600px){
    .react-typewriter-text {
        font-size: 2.5em;
    }
    .with-right-margin{
        margin-bottom: 4px;
        margin-right: 0;
    }
    .title-container {
        padding-right: 8px;
        padding-left: 8px;
    }
    .definition-container{
        margin-right: 12px;
        margin-left: 12px;
        font-size: 16px;
    }
    .definitions-margin {
        margin: 6vh 16px 50px !important;
    }
    .definition-container {
        margin-right: 8px;
        margin-left: 8px;
        font-size: 15px;
    }
    .spelling-container{
        font-size: 16px;
    }
    .menu{
        font-size:13px;
    }
    .menu-item{
        display: flex;
        flex-direction: column;
    }
    #separator{
        margin: 0 4px !important;
    }
    .new-word{
        padding: 0 16px;
    }
}
.footer {
    width: fit-content;
    margin:0 auto;
    margin-bottom: 12px;
    max-width: 90vw;
    text-align: center;
    text-align: -webkit-center;
}
.footer-element {
    margin-bottom: 4px;
}
.small-text {
    font-size: small;
    color: var(--secondaryTextColor);
    font-family: "Helvetica Neue", serif;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid rgba(75, 75, 75, 0.25);
    margin: 1em 8em;
    padding: 0;
}
::placeholder {
    color: var(--primaryColor);
    opacity: 0.3;
}

.word-entry {
    outline: 0;
    border-width: 0 0 2px;
    border-color: var(--primaryColor);
    color: var(--primaryColor);
    background-color: hsla(0, 0%, 100%, 0);
    resize: none;
    width: 100%;
    max-width: 650px;
    font-size: 4em;
    outline: none;
    padding: 0;
    margin: 0;
}

#word-entry-form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.definitions {
    width: fit-content;
    max-width: min(650px, 90vw);
    padding-top: 24px;
    background-color: var(--backgroundColor);
}

.definitions-margin {
    background-color: var(--backgroundColor);
    width: fit-content;
    max-width: min(650px, 90vw);
    margin: 8vh auto 50px;
}

/* width */
::-webkit-scrollbar {
    border-radius: 8px;
    width: 10px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 8px;
    height: 6px;
}


body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    letter-spacing: .021em;
}

.menu {
    text-align: center;
    margin: auto;
    margin-top: 32px;
    width: fit-content;
    color: var(--textColor);
}

a {
    color: var(--primaryColor);
    opacity: 0.7;
    text-decoration: none;
}
canvas {
    display:none;
    letter-spacing: 0.020em;
    line-height: 1.2em;
}
a:hover{
    color: var(--primaryColor);
    opacity: 1.0;
    text-decoration: none;
}
.menu-item{
    font-weight: bold;
}

#separator {
    margin: 0 12px !important;
    font-weight: 900;
    font-family: monospace;
    font-size: larger;
    color: var(--primaryColor) !important;
}

.new-word {
    width: fit-content;
    height: fit-content;
    margin: 12vh auto;
    font-family: 'Libre Baskerville', serif !important;
}
input{
    font-family: 'Libre Baskerville', serif !important;
}

.sharing-disclaimer {
    opacity: 0.7;
    color: var(--secondaryTextColor);
    font-size: 11px;
    max-width: 270px;
}

.sharing-site-name {
    color: var(--primaryColor);
    font-weight: 800;
    letter-spacing: 0.08em;
}

.sharing-container {
    display: none;
    justify-content: space-between;
    align-items:flex-end;
    padding: 4px 8px;
}

@keyframes ldio-oa40i20wek {
    0% {
        transform: translate(7px, 41px) scale(0);
    }
    25% {
        transform: translate(7px, 41px) scale(0);
    }
    50% {
        transform: translate(7px, 41px) scale(1);
    }
    75% {
        transform: translate(41px, 41px) scale(1);
    }
    100% {
        transform: translate(75px, 41px) scale(1);
    }
}

@keyframes ldio-oa40i20wek-r {
    0% {
        transform: translate(75px, 41px) scale(1);
    }
    100% {
        transform: translate(75px, 41px) scale(0);
    }
}

@keyframes ldio-oa40i20wek-c {
    0% {
        background: var(--primaryColor)
    }
    25% {
        background: var(--primaryColor)
    }
    50% {
        background: var(--primaryColor)
    }
    75% {
        background: var(--primaryColor)
    }
    100% {
        background: var(--primaryColor)
    }
}

.ldio-oa40i20wek div {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transform: translate(41px, 41px) scale(1);
    background: var(--primaryColor);
    animation: ldio-oa40i20wek 1.7857142857142856s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-oa40i20wek div:nth-child(1) {
    background: var(--primaryColor);
    transform: translate(75px, 41px) scale(1);
    animation: ldio-oa40i20wek-r 0.4464285714285714s infinite cubic-bezier(0, 0.5, 0.5, 1), ldio-oa40i20wek-c 1.7857142857142856s infinite step-start;
}

.ldio-oa40i20wek div:nth-child(2) {
    animation-delay: -0.4464285714285714s;
    background: var(--primaryColor);
}

.ldio-oa40i20wek div:nth-child(3) {
    animation-delay: -0.8928571428571428s;
    background: var(--primaryColor);
}

.ldio-oa40i20wek div:nth-child(4) {
    animation-delay: -1.3392857142857142s;
    background: var(--primaryColor);
}

.ldio-oa40i20wek div:nth-child(5) {
    animation-delay: -1.7857142857142856s;
    background: var(--primaryColor);
}

.loadingio-spinner-ellipsis-vcn6x1qbqcs {
    width: 48px;
    height: 48px;
    display: inline-block;
    overflow: hidden;
    background: none;
}

.ldio-oa40i20wek {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.48);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-oa40i20wek div {
    box-sizing: content-box;
}

.no-resp-img {
    width: 48px;
}


.bmc-button img {
    height: 17px !important;
    width: 18px !important;
    margin-bottom: 1px !important;
    margin-left: 6px !important;
    border: none !important;
    vertical-align: middle !important;
}

.bmc-button {
    animation: color-change 2s infinite;
    justify-content: center;
    padding: 7px 10px 7px 10px !important;
    line-height: 35px !important;
    height: 25px !important;
    min-width: 140px !important;
    text-decoration: none !important;
    display: inline-flex !important;
    padding: 7px 10px 7px 10px !important;
    font-size: 18px !important;
    letter-spacing: 0.6px !important;
    margin: 0 auto !important;
    font-family: 'Cookie', cursive !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

